.chapter-content,
.chapter-metadata h1 {
    font-weight: normal;
}

.chapter-content h2 {
    font-size: var(--chakra-fontSizes-2xl);
    color: var(--chakra-colors-red-500);
}

.chapter-content h2 strong {
    font-weight: normal !important;
}

.chapter-content h3 {
    font-size: var(--chakra-fontSizes-xl);
    font-weight: normal;
}

.chapter-content p p::after,
.rf-helper-text {
    content: '';
    display: block;
    margin-bottom: 16px;
    height: 0;
}

.chapter-content h2::after,
.chapter-content h3::after {
    content: '';
    display: block;
    margin-bottom: 8px;
    height: 0;
}

.chapter-content h2::before {
    content: '';
    display: block;
    margin-bottom: 8px;
    height: 0;
}

.chapter-content ul {
    margin-bottom: 30px;
}

li {
    margin-left: 30px;
}