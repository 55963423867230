.chapter-editor h1 {
    font-weight: normal;
}

.chapter-editor h2 {
    font-size: var(--chakra-fontSizes-2xl);
    color: var(--chakra-colors-red-500);
}

.chapter-editor h2 strong {
    font-weight: normal !important;
}

.chapter-editor h3 {
    font-size: var(--chakra-fontSizes-xl);
    font-weight: normal;
}

.chapter-editor p {
    margin-bottom: 16px;
}

.chapter-editor h2::after,
.chapter-editor h3::after {
    content: '';
    display: block;
    margin-bottom: 8px;
    height: 0;
}

.chapter-editor h2::before {
    content: '';
    display: block;
    margin-bottom: 8px;
    height: 0;
}