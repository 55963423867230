.default-editor .ql-editor {
    height: 400px;
}

.reflections-editor .ql-editor {
    height: 200px;
}

.ql-toolbar {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-color: "#CBD5E0";
    text-align: left;
}

.ql-container {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-color: "#CBD5E0";
}

.ql-container p {
    line-height: 30px;
    font-size: var(--chakra-fontSizes-lg);
}

.ql-container h1 {
    line-height: 30px;
    font-size: var(--chakra-fontSizes-3xl);
}

.ql-container h2 {
    line-height: 30px;
    font-size: var(--chakra-fontSizes-2xl) !important;
}

.ql-container h3 {
    line-height: 30px;
    font-size: var(--chakra-fontSizes-xl) !important;
}

.ql-container ul {
    margin-bottom: 30px;
    font-size: var(--chakra-fontSizes-md);
}

.ql-video {
    width: 100%;
    height: 36vw;
}

.ql-align-right {
    text-align: right !important;
}

.ql-align-left {
    text-align: left !important;
}

.ql-align-center {
    text-align: center !important;
}

.ql-align-justify {
    text-align: justify !important;
}

.ql-editor p,
.ql-editor::before {
    font-size: var(--chakra-fontSizes-md);
}

.ql-editor li {
    margin-left: 0px;
}