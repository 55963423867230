.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
    background-color: var(--chakra-colors-red-500);
}

.react-datepicker__input-container,
.react-datepicker__input-container input {
    cursor: pointer !important;
    background-color: transparent;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__input-time-container {
    padding-top: 5px;
    padding-right: 12px;
}